import React from 'react';
import Navbar2 from '../components/Navbar/Navbar2';
import Footer from '../components/Footer/Footer';
import Marquee from '../components/Marquee/Marquee';
import { Outlet } from 'react-router-dom';

const AppLayout = () => {
    return (
        <div>
            <Marquee />
            <Navbar2 />
            <Outlet />
            <Footer />
        </div>
    )
}

export default AppLayout;