import React, { useState } from 'react';
import Fuse from 'fuse.js';
import { useNavigate } from 'react-router-dom';
import companiesData from '../../data/companies.json';
import { useDispatch } from 'react-redux';
import { stockActions } from '../../redux/features/stock/stockSlice';
import stockDetailsData from '../../data/bse_stocks_website_urls.json';

const NavSearch = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [searchResults, setSearchResults] = useState(null);
    const [inputValue, setInputValue] = useState("");
    const [searchedCompany, setSearchedCompany] = useState(null);
    const fuse = new Fuse(companiesData, {
        includeScore: true,
        threshold: 0.4,
        keys: [
            'companyName',
            'bseScripCode',
            'nseCode',
            'meta'
        ]
    });

    function searchWithFuse(query) {
        if (!query) {
            return [];
        }
        return fuse.search(query)?.map((result) => result.item);
    }

    const handleSearch = (e) => {
        const query = e.target.value;
        setInputValue(e.target.value);
        const results = searchWithFuse(query);
        setSearchResults(results);
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && searchedCompany) {
            const stockDetails = stockDetailsData[searchedCompany.nseCode];
            dispatch(stockActions.setStock({ code: stockDetails.scrip_code, stockName: searchedCompany.nseCode, stockLogo: stockDetails.logo, stockWebsite: stockDetails.website_url }));
            setInputValue("");
            setSearchResults([]);
            navigate(`/stock/${searchedCompany.nseCode}`);
        } else if (e.key === 'Tab') {
            e.preventDefault();
            setInputValue(searchResults[0]?.companyName);
            setSearchedCompany(searchResults[0]);
            setSearchResults([]);
        } else if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
            e.preventDefault();
            const topFiveResults = searchResults?.slice(0, 5);
            const currentIndex = topFiveResults?.findIndex(result => result.companyName === inputValue);
            let newIndex;
            const totalResults = topFiveResults.length;
            if (e.key === 'ArrowDown') {
                newIndex = currentIndex === totalResults - 1 ? 0 : currentIndex + 1;
            } else {
                newIndex = currentIndex === 0 ? totalResults - 1 : currentIndex - 1;
            }
            setInputValue(topFiveResults[newIndex].companyName);
            setSearchedCompany(topFiveResults[newIndex]);
            const results = document.querySelectorAll('.nav-search-result');
            results.forEach((item, i) => {
                item.classList[i === newIndex ? 'add' : 'remove']('nav-search-result-active');
            });
        }
    };

    const handleResultClick = (e) => {
        const companyName = e.target.innerHTML;
        const clickedCompany = searchResults?.find(result => result.companyName === companyName);
        setSearchedCompany(clickedCompany);
        const stockDetails = stockDetailsData[clickedCompany.nseCode];
        dispatch(stockActions.setStock({ code: stockDetails.scrip_code, stockName: clickedCompany.nseCode, stockLogo: stockDetails.logo, stockWebsite: stockDetails.website_url }));
        navigate(`/stock/${clickedCompany.nseCode}`);
        setInputValue("");
        setSearchResults([]);
    };

    return (
        <div className="navbar-search">
            <input
                type="search"
                placeholder='Search'
                onChange={handleSearch}
                onKeyDown={(e) => handleKeyDown(e)}
                value={inputValue}
            />
            <div className='nav-search-results'>
                {searchResults?.slice(0, 5).map((result, index) => (
                    <div className='nav-search-result' key={index}>
                        <div className='nav-search-result-title' onClick={(e) => handleResultClick(e)}>
                            {result.companyName}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default NavSearch;