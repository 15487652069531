import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
// import { BrowserRouter as Router } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { StateContext } from './context/StateContext';
import store from './redux/app/store';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const persistor = persistStore(store);

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <GoogleOAuthProvider clientId='850095699475-ojvf29tdv8d2ijunh8078s4np2vigg0k.apps.googleusercontent.com'>
          <StateContext>
            {/* <Router> */}
              <App />
              <ToastContainer theme='dark' autoClose={2500} position="bottom-right" closeOnClick draggable pauseOnHover stacked newestOnTop={true}/>
            {/* </Router> */}
          </StateContext>
        </GoogleOAuthProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);