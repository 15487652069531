import React, { lazy, Suspense } from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Loading from "../pages/Loading";
// import LandingPageLayout from "../Layout/LandingPageLayout";
import AppLayout from "../Layout/AppLayout";
// import ProtectedRoutes from "./ProtectedRoutes";

// const LazyLandingPage = lazy(() => import("../pages/LandingPage"));
const LazyHomePage = lazy(() => import('../pages/Home'));
const LazyLoginPage = lazy(() => import('../pages/Login'));
const LazySignupPage = lazy(() => import('../pages/Register'));
const LazyAboutPage = lazy(() => import('../pages/About'));
const LazyTeamPage = lazy(() => import('../pages/Team'));
const LazyProductPage = lazy(() => import('../pages/ProductPage'));

const LazyBlogPage = lazy(() => import('../components/AboutPage/BlogPage'));


const router = createBrowserRouter([
    // {
    //     path: '/',
    //     element: <LandingPageLayout />,
    //     id: 'root',
    //     children: [
    //         {
    //             index: true, element: (
    //                 <Suspense fallback={<Loading />}>
    //                     <LazyLandingPage />
    //                 </Suspense>
    //             )
    //         }
    //     ]
    // },
    {
        path: '/',
        id: 'root',
        element: <AppLayout />,
        children: [
            {
                index: true, element: (
                    <Suspense fallback={<Loading />}>
                        <LazyHomePage />
                    </Suspense>
                )
            },
            {
                path: 'about',
                id: 'about',
                children: [
                    {
                        index: true,
                        element: (
                            <Suspense fallback={<Loading />}>
                                <LazyAboutPage />
                            </Suspense>
                        )
                    },
                    {
                        path: '/about/blog/:id',
                        element: (
                            <Suspense fallback={<Loading />}>
                                <LazyBlogPage />
                            </Suspense>
                        )
                    }
                ]
            },
            {
                path: 'login',
                element: (
                    <Suspense fallback={<Loading />}>
                        <LazyLoginPage />
                    </Suspense>
                )
            },
            {
                path: 'register',
                element: (
                    <Suspense fallback={<Loading />}>
                        <LazySignupPage />
                    </Suspense>
                )
            },
            {
                path: 'team',
                element: (
                    <Suspense fallback={<Loading />}>
                        <LazyTeamPage />
                    </Suspense>
                )
            },
            {
                path: 'stock/:id',
                element: (
                    <Suspense fallback={<Loading />}>
                        <LazyProductPage />
                    </Suspense>
                )
            }
        ]
    }
]);

const PageRoutes = () => {
    return <RouterProvider router={router} />
}

export default PageRoutes;
